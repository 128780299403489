import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { TbPlus } from "react-icons/tb";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import ButtonDanger from "@areas/shared/components/buttons/ButtonDanger";
import CustomModal from "@areas/shared/components/personal/modals/CustomModal";
import CustomModalDelete from "@areas/shared/components/personal/modals/CustomModalDelete";
import { fetchWrapper } from "@helpers/fetchwrapper";

import Table from "./blocks/Table";
import {
  initialValues,
  validationSchema,
  confirm,
  ModalBodyAdd,
} from "./blocks/ModalAdd";

function Frame(props) {
  const { company: currentCompany } = useSelector((x) => x.auth);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [headerModal, setHeaderModal] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [event, setEvent] = useState([]);

  const loadData = useCallback(
    () => {
      setLoad(true);
      fetchWrapper
        .get(
          `/v1/api/app/getEventByIdCompany?offset=${
            page * perPage - perPage
          }&limit=${perPage}&idCompany=${currentCompany.IdCompany}`
        )
        .then((res) => {
          setData(res.data);
          setTotal(res.total);
          setLoad(false);
        });
    },
    [page, perPage, currentCompany]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const toggle = useCallback(
    (event) => {
      if (showModal) {
        setShowModal(false);
        setEvent(null);
      } else {
        if (!event) {
          setHeaderModal("Добавление мероприятия");
        } else {
          setHeaderModal("Редактирование мероприятия");
        }
        setEvent(event);
        setShowModal(true);
      }
    },
    [showModal]
  );
  const toggleDelete = useCallback(
    (event) => {
      if (showModalDelete) {
        setShowModalDelete(false);
        setEvent(null);
      } else {
        setEvent(event);
        setShowModalDelete(!showModalDelete);
      }
    },
    [showModalDelete]
  );
  const handleDelete = () => {
      if (event) {
        fetchWrapper
          .post(
            `/v1/personal/deleteEvent?idTicket=${event.Id}`,
            null,
            false
          )
          .then(() => {
            setShowModalDelete(false);
            setEvent(null);
            loadData();
          });
      }
    };
  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Мероприятия</h5>
              <ButtonDanger onClick={() => toggle()}>
                <TbPlus />
                Добавить мероприятие
              </ButtonDanger>
            </div>
          </CardHeader>
          <CardBody>
            <Table
              load={load}
              data={data}
              total={total}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
              toggleModal={toggle}
              toggleDelete={toggleDelete}
            />
          </CardBody>
        </Card>
        <CustomModal
          isOpen={showModal}
          toggle={toggle}
          header={headerModal}
          body={(validation) => <ModalBodyAdd validation={validation} />}
          validateYupSchema={validationSchema}
          inititalValues={() => initialValues(event, currentCompany)}
          cancel="Закрыть"
          confirm="Сохранить"
          confirmFunction={(values) => {
            confirm(values, loadData);
          }}
        />
        <CustomModalDelete
                  isOpen={showModalDelete}
                  onDeleteClick={handleDelete}
                  onCloseClick={toggleDelete}
                />
      </Animation>
    </div>
  );
}

export default Frame;
