import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import moment from "moment";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (event, company) => {
  let now = moment().format("DD-MM-YYYY");

  let tags = [];

  if (event && event.Tags) {
    event.Tags.forEach((element) => {
      tags.push({ value: element, label: element });
    });
  }

  return {
    id: (event && event.Id) || null,
    name: (event && event.Name) || "",
    description: (event && event.Description) || "",
    type: (event && event.IdType) || 1,
    dateStart: (event && moment(event.DateStart).format("DD-MM-YYYY")) || now,
    dateEnd: (event && moment(event.DateEnd).format("DD-MM-YYYY")) || now,
    address: 1,
    tags: tags,
    idCompany: (company && company.IdCompany) || 0,
  };
};

const validationSchema = () => {
  return Yup.object({});
};

const confirm = (values, successFunc) => {  
  var sdate = moment(values.dateStart, 'DD-MM-YYYY').format("YYYY-MM-DD");
  var edate = moment(values.dateEnd, 'DD-MM-YYYY').format("YYYY-MM-DD");
  console.log(values);
  let data = new FormData();
  data.append("idType", values.type);
  data.append("name", values.name);
  data.append("description", values.description);
  data.append("idCompany", values.idCompany);
  data.append("dateStart", sdate);
  data.append("dateEnd", edate);

  if (values.tags) {
    values.tags.forEach((element) => {
      data.append("tags[]", element.value);
    });
  }

  if (values.id) {
    data.append("id", values.id);

    fetchWrapper
      .post(`/v1/personal/updateevent`, data, false)
      .then(() => successFunc(values.idCompany));
  } else {
    fetchWrapper
      .post(`/v1/personal/insertevent?idAddress=${values.address}`, data, false)
      .then(() => successFunc(values.idCompany));
  }
};

const types = [
  {
    value: 1,
    label: "Общественное",
  },
  {
    value: 2,
    label: "День открытых дверей",
  },
];

function ModalBodyAdd({ validation }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="address"
        defaultValue={validation.values.address || 1}
      />
      <Col sm={12}>
        <div>
          <Label htmlFor="eventName-field" className={styles.label}>
            Наименование
          </Label>
          <Input
            name="name"
            id="eventName-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите наименование"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="eventDescription-field" className={styles.label}>
            Описание
          </Label>
          <Input
            name="description"
            id="eventDescription-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите описание"
            type="textarea"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ""}
            invalid={
              validation.touched.description && validation.errors.description
                ? true
                : false
            }
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.description}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="eventType-field" className={styles.label}>
            Тип
          </Label>
          <Select
            name="type"
            id="eventType-field"
            options={types}
            value={
              validation.values.type
                ? types.find((p) => p.value === validation.values.type)
                : []
            }
            placeholder="Выберите тип"
            onChange={(e) => {
              validation.setFieldValue("type", e.value);
            }}
            onBlur={() => validation.setFieldTouched("type")}
          />
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <Label htmlFor="eventDateStart-field" className={styles.label}>
            Начало
          </Label>
          <Flatpickr
            id="eventDateStart-field"
            name="dateStart"
            placeholder="Выберите дату"
            className={`form-control ${styles.input}`}
            data-date-format="d.m.Y"
            options={{
              locale: Russian,
            }}
            value={validation.values.dateStart || moment().format("DD-MM-YYYY")}
            onChange={([date]) => {
              validation.setFieldValue(
                "dateStart",
                moment(date).format("DD-MM-YYYY")
              );
              validation.setFieldTouched("dateStart");
            }}
          />
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <Label htmlFor="eventDateEnd-field" className={styles.label}>
            Конец
          </Label>
          <Flatpickr
            id="eventDateEnd-field"
            name="dateEnd"
            placeholder="Выберите дату"
            className={`form-control ${styles.input}`}
            data-date-format="d.m.Y"
            options={{
              locale: Russian,
            }}
            value={validation.values.dateEnd || moment().format("DD-MM-YYYY")}
            onChange={([date]) => {
              validation.setFieldValue(
                "dateEnd",
                moment(date).format("DD-MM-YYYY")
              );
              validation.setFieldTouched("dateEnd");
            }}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="eventTags-field" className={styles.label}>
            Теги
          </Label>
          <CreatableSelect
            name="tags"
            id="eventTags-field"
            placeholder="Введите теги"
            options={[]}
            isMulti={true}
            value={validation.values.tags || []}
            onChange={(e) => {
              validation.setFieldValue("tags", e);
            }}
            formatCreateLabel={(value) => `Добавить ${value}`}
            noOptionsMessage={() => "Ничего не найдено"}
            onBlur={() => validation.setFieldTouched("tags")}
          />
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
